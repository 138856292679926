import { fetchGet, fetchPost } from "./index";
import type {
  DynamicTable,
  SendFileLogListRes,
  SendFileLogListParams,
} from "@/views/Communication/type";

export const tableList = (): Promise<DynamicTable[]> =>
  fetchGet("/commapi/dynamicTable/list");

export const typeList = (): Promise<any> =>
  fetchGet("/commapi/dynamicTable/type/list");

export const sendFileLogList = (
  params: SendFileLogListParams,
  page: number,
  size: number
): Promise<SendFileLogListRes> => {
  return fetchPost("/commapi/sendFileLog/page/list", params, {
    params: {
      page,
      size,
    },
  });
};

export const transferFile = (ids: number[]) =>
  fetchPost("/commapi/manualDwh/transfer/file", ids);
